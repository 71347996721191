<template>
    <div>
        <h6>
            <default-button
                flavor="link"
                size="sm"
                :block="true"
                :class="{ active }"
                :disabled="disabled"
                @click.prevent="$emit('toggle')"
            >
                <open-icon glyph="angle-right" class="mr-1" />
                <span v-if="active">{{ activeTitle }}</span>
                <span v-else>{{ title }}</span>
            </default-button>
        </h6>
        <slot></slot>
    </div>
</template>

<script>
import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "SearchSectionSingleValue",
    components: {
        DefaultButton,
        OpenIcon
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        activeTitle: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
