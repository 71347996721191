<template>
    <div v-if="!!address_as_string">
        {{ address_as_string }}
        <span
            v-if="addressCount > 1"
            class="text-small text-gray ml-2"
        >
            +&nbsp;{{ addressCount - 1 }}&nbsp;more...
        </span>
    </div>
</template>

<script>
import { string_utilities } from "@/nibnut/mixins"

export default {
    name: "AddressesView",
    mixins: [string_utilities],
    computed: {
        address_as_string () {
            if(!this.address) return ""
            const line4 = this.string_concat(", ", this.address.city, this.string_concat(" ", this.address.state, this.address.zip), this.address.county_name)
            return this.string_concat(", ", this.address.line1, this.address.line2, line4)
        }
    },
    props: {
        address: {
            type: Object,
            default () {
                return {}
            }
        },
        addressCount: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            expanded: false
        }
    }
}
</script>
