<template>
    <page
        id="dashboard"
        :title="$root.translate('Dashboard')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <Split
            :gutterSize="small_screen ? 0 : 16"
            :direction="small_screen ? 'vertical' : 'horizontal'"
            class="dashboard"
        >
            <SplitArea :size="small_screen ? 100 : 25" :minSize="320">
                <aside ref="aside">
                    <!--
                    <tabs
                        :tabs="tabs"
                        :current-tab-id="current_tab_id"
                        @click="current_tab_id=$event"
                    >
                        <search-panel
                            v-if="current_tab_id === 'search'"
                            :criteria="criteria"
                            :disabled="loading"
                        />
                        <user-searches
                            v-else-if="current_tab_id === 'my-searches'"
                            @load="load_saved_search"
                        />
                    </tabs>
                    //-->
                    <search-panel
                        :criteria="criteria"
                        :disabled="loading"
                    />
                    <div class="search-footer">
                        <!--
                        <div v-if="!!saved_search_id" class="text-gray text-small mb-2">
                            {{ saved_current_search.content.name }}
                        </div>
                        //-->
                        <div
                            v-if="criteria_is_set || !!rows.length"
                            class="text-center py-2 search-tools"
                        >
                            <!--
                            <default-button
                                v-if="criteria_is_set"
                                flavor="link"
                                :disabled="loading"
                                @click.prevent="criteria_save"
                            >
                                {{ $root.translate("Save") }}
                            </default-button>
                            //-->
                            <default-button
                                v-if="rows.length"
                                flavor="link"
                                :disabled="loading"
                                class="ml-4"
                                @click.prevent="export_rows"
                            >
                                {{ $root.translate("Export") }}
                            </default-button>
                            <!--
                            <default-button
                                v-if="rows.length"
                                flavor="link"
                                :disabled="loading"
                                class="ml-4"
                                @click.prevent="enrich_rows"
                            >
                                {{ $root.translate("Enrich") }}
                            </default-button>
                            //-->
                        </div>
                        <div class="text-center mt-4">
                            <default-button
                                :disabled="loading"
                                @click.prevent="criteria_reset"
                            >
                                {{ $root.translate("Reset All") }}
                            </default-button>
                            <default-button
                                color="primary"
                                :disabled="!criteria_is_set || loading"
                                :waiting="loading"
                                class="ml-4"
                                @click.prevent="criteria_apply"
                            >
                                {{ $root.translate("Search") }}
                            </default-button>
                        </div>
                    </div>
                </aside>
            </SplitArea>
            <SplitArea :size="small_screen ? 100 : 75" :minSize="small_screen ? 320 : 800">
                <section>
                    <data-table
                        id="search-results"
                        :columns="visible_columns"
                        :loading="loading"
                        :force-loader="true"
                        :rows="filtered_rows"
                        :search="state.search"
                        :total="state.total"
                        :found="state.found"
                        :current-page="state.page"
                        :total-pages="nb_pages"
                        :per-page="state.per_page"
                        :per-page-options="per_page_options"
                        :can-add="false"
                        :searchable="false"
                        :selection="selected_row_ids"
                        class="non-sticky"
                        @sort="sort_by"
                        @clear-selection="clear_selection"
                        @filter-by-selection="filter_by_selection"
                        @search="search"
                        @click="record_edit(entity, $event.uuid)"
                        @page="goto_page"
                        @select="select_row"
                        @display="change_per_page"
                    >
                        <template
                            v-slot:summary="{ row }"
                        >
                            <h4 class="mt-4">
                                {{ row.computed_name }}
                            </h4>
                            <h4>
                                {{ row.computed_accounts_total | nibnut.currency }}
                                &nbsp;&bull;&nbsp;
                                <defined-term
                                    class="text-gray"
                                    :definition="accounts_list_for_row(row)"
                                >
                                    {{ translate("1 Account:::{n} Accounts", { n: nibnut_filter("nibnut.number", [row.computed_accounts_count, "0,0"]) }, row.computed_accounts_count) }}
                                </defined-term>
                            </h4>
                            <addresses-view
                                :address="address_for_row(row)"
                                :address-count="row.address_ids.length"
                            />
                        </template>
                        <template
                            v-slot:tbody="{ row, field }"
                        >
                            <div v-if="field === 'computed_accounts_count'">
                                <defined-term
                                    :definition="accounts_list_for_row(row)"
                                >
                                    {{ translate("1 Account:::{n} Accounts", { n: nibnut_filter("nibnut.number", [row.computed_accounts_count, "0,0"]) }, row.computed_accounts_count) }}
                                </defined-term>
                            </div>
                            <div v-else-if="field === 'computed_accounts_total'">
                                {{ row.computed_accounts_total | nibnut.currency }}
                            </div>
                            <addresses-view
                                v-else-if="field === 'current_address_id'"
                                :address="address_for_row(row)"
                                :address-count="row.address_ids.length"
                            />
                            <span v-else-if="field === 'amount'">{{ row.amount | nibnut.currency }}</span>
                            <div v-else-if="field === 'owner_relationship_id'">
                                <defined-term
                                    :definition="relationship_for_row(row).name || ''"
                                >
                                    {{ relationship_for_row(row).code }}
                                </defined-term>
                            </div>
                            <span v-else>{{ row[field] }}</span>
                        </template>
                        <template
                            v-slot:empty
                        >
                            <div>
                                <div class="empty-icon">
                                    <open-icon glyph="search" size="3x" />
                                </div>
                                <p class="empty-title h5">
                                    {{ $root.translate("Set-up your search criteria on the left, then hit the 'Search' button.") }}
                                </p>
                            </div>
                        </template>
                    </data-table>
                </section>
            </SplitArea>
        </Split>

        <!--
        <prompt
            v-if="prompting"
            v-bind="prompt_props"
            @cancel="done_prompting"
            @save="create_current_search"
        />
        //-->
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"
import { is_page, is_remote_data_table_source, crud_utilities, string_utilities } from "@/nibnut/mixins"
// import { handles_saved_searches } from "@/custom/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"
import SearchPanel from "./dashboard/SearchPanel"
import AddressesView from "./dashboard/AddressesView"
import { DefinedTerm } from "@/custom/components"
import { Split, SplitArea } from "vue-split-panel"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, string_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        SearchPanel,
        AddressesView,
        DefinedTerm,
        Split,
        SplitArea
    },
    mounted () {
        this.maybe_load_list_values()
        setTimeout(() => {
            if(this.$refs.aside) this.$refs.aside.style.height = `calc(100vh - ${this.$refs.aside.offsetTop}px)`
        }, 300)
    },
    watch: {
        $route: "maybe_load_list_values",
        profile_id: "maybe_load_list_values"
        // saved_search_id: "sync_state_search_id"
    },
    methods: {
        /*
        sync_state_search_id () {
            this.set_state_value("saved_search_id", this.saved_search_id)
            if(this.saved_search_id) {
                this.criteria = { ...this.saved_current_search.content.criteria }
                this.criteria_apply()
            } else this.criteria_reset()
        },
        */
        maybe_load_list_values () {
            if(this.profile_id) {
                // if(this.$route.params.id) this.saved_search_id = this.$route.params.id
                // else
                if(!!this.state.criteria && !!Object.keys(this.state.criteria).length) {
                    this.criteria = { ...this.state.criteria }
                    this.criteria_apply()
                }
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "property_type"
                    }
                ).catch(error => {
                    console.error(error)
                })
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "owner_relationship"
                    }
                ).catch(error => {
                    console.error(error)
                })
            }
        },
        criteria_reset () {
            this.reset_state(true)
            this.criteria = {
                query: "",
                type: "P", // P|B
                amount: null,
                estate: null, // T|F
                holders: null,
                property_types: [],
                relationships: [],
                multiple_accounts: null, // T|F
                // genealogy: none, requested, completed
                // contact_verified: null // T|F
                addresses: [],

                // birth: null, // MMDDYYYY or YYYY
                // age_range: null,
                // phone_numbers: [],
                reported_year_range: null,

                unidentified: null, // T|F
                status: []
            }
            // this.saved_search_id = 0
            this.criteria_apply()
        },
        savable_criteria () {
            return { ...this.criteria }
        },
        /*
        load_saved_search (saved_search) {
            this.saved_search_id = saved_search.id
            this.current_tab_id = "search"
        },
        */
        criteria_apply () {
            this.selected_row_ids = []
            this.set_state_values({
                page: 1,
                criteria: this.savable_criteria()
            })
            this.refresh_remotely()
        },
        /*
        criteria_save () {
            this.save_current_search(this.savable_criteria())
        },
        */
        refresh_remotely () {
            this.applying = true
            this.refresh().then(() => {
                this.applying = false
            })
        },
        accounts_list_for_row (row) {
            const rows = []
            const accounts = this.entity_records("account", row.account_ids) || []
            accounts.forEach(account => {
                rows.push(`${this.nibnut_filter("nibnut.currency", [account.amount])} - ${this.property_type_definition(account.property_type_id)}`)
            })
            return rows.join("\n")
        },
        address_for_row (row) {
            return this.entity_record("address", row.current_address_id) || {}
        },
        property_type_definition (property_type_id) {
            return (this.entity_record("property_type", property_type_id) || {}).name
        },
        relationship_for_row (row) {
            return {}
            // return this.entity_record("owner_relationship", row.owner_relationship_id) || {}
        },
        sort_by (field, sort) {
            const columns = this.columns[field] ? this.columns : this.visible_columns
            const column = columns[field]
            if(!column || (column.sort === false)) return // false is not sortable

            Object.keys(columns).forEach(column_id => {
                if(column_id === field) columns[column_id].sort = sort || "asc"
                else if(columns[column_id].sort !== false) columns[column_id].sort = null
            })

            this.set_state_values({
                sort_by: field,
                sort_dir: column.sort
            })
            this.refresh_remotely()
        },
        goto_page (page) {
            if((this.state.page === page) || (page <= 0) || (page > this.nb_pages)) return
            this.set_state_value("page", page)
            this.refresh_remotely()
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        search_rows (rows) {
            return rows
        },
        clear_selection () {
            this.filtered_by_selection = false
            this.selected_row_ids = []
            this.reset_state(true)
            this.set_state_value("page", 1)
            this.refresh_remotely()
        },
        export_rows () {
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: this.entity,
                    action: "request-export",
                    data: {
                        ...this.state,
                        selected_row_ids: this.selected_row_ids
                    },
                    passthru: true
                }
            ).then(response => {
                this.$success(response.success)
            }).catch(error => {
                this.$error(error.message)
            })
        }
        /*
        enrich_rows () {
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: this.entity,
                    action: "request-enrichment",
                    data: {
                        ...this.state,
                        selected_row_ids: this.selected_row_ids
                    },
                    passthru: true
                }
            ).then(response => {
                this.$success(response.success)
            }).catch(error => {
                this.$error(error.message)
            })
        }
        */
    },
    computed: {
        ...mapGetters(["entity_record"]),
        /*
        tabs () {
            return [
                { id: "search", title: this.$root.translate("Search") },
                { id: "my-searches", title: this.$root.translate("My Searches") }
            ]
        },
        */
        can_refresh () {
            return this.profile_id && this.applying
        },
        fields () {
            return ["fieldset::list", "ns::account;fieldset::owner-list", "ns::address;fieldset::owner-list"]
        },
        criteria_is_set () {
            return !!this.criteria && (
                (!!this.criteria.query && (this.criteria.query.length >= this.min_search_length)) ||
                (!!this.criteria.amount && (!!this.criteria.amount.min || !!this.criteria.amount.max)) ||
                (this.criteria.estate !== null) ||
                (!!this.criteria.holders && !!this.criteria.holders.length && !!this.criteria.holders[0]) ||
                (!!this.criteria.property_types.length && !!this.criteria.property_types[0]) ||
                (!!this.criteria.relationships.length && !!this.criteria.relationships[0]) ||
                (this.criteria.multiple_accounts !== null) ||
                (!!this.criteria.addresses.length && !!this.criteria.addresses[0].value) ||
                // (this.criteria.birth !== null) ||
                // (!!this.criteria.age_range && (!!this.criteria.age_range.min || !!this.criteria.age_range.max)) ||
                (!!this.criteria.reported_year_range && (!!this.criteria.reported_year_range.min || !!this.criteria.reported_year_range.max)) ||
                // (!!this.criteria.phone_numbers.length && !!this.criteria.phone_numbers[0]) ||
                (this.criteria.unidentified !== null) ||
                (!!this.criteria.status.length) ||
                (this.criteria.type !== null)
            )
        }
    },
    data () {
        return {
            current_tab_id: "search",

            entity: "owner",
            relation_ids: ["account", "address"],
            columns: {
                computed_name: { label: "Owner", sort: null, type: "alpha" },
                computed_accounts_count: { label: "# Account(s)", sort: null, type: "numeric", head_class: "text-right", cell_class: "text-right" },
                computed_accounts_total: { label: "Total Amount", sort: "desc", type: "amount", head_class: "text-right", cell_class: "text-right" },
                current_address_id: { label: "Address(es)", sort: false }
                // owner_relationship_id: { label: "Relation to Owner", sort: false }
            },
            default_state: {
                per_page: 100, // 10, 50
                page: 1,
                sort_by: "computed_accounts_total",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                criteria: {}
                // saved_search_id: 0
            },
            criteria: {
                query: "",
                amount: null,
                type: "P", // P|B
                estate: null, // T|F
                holders: null,
                property_types: [],
                relationships: [],
                multiple_accounts: null, // T|F
                // genealogy: none, requested, completed
                // contact_verified: null // T|F
                addresses: [],

                // birth: null, // MMDDYYYY or YYYY
                // age_range: null,
                // phone_numbers: [],
                reported_year_range: null,

                unidentified: null, // T|F
                status: []
            },
            per_page_options: [
                { id: 10, name: "10" },
                { id: 50, name: "50" },
                { id: 100, name: "100" }
            ],
            applying: false,
            selected_row_ids: []
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

.nibnut.home {
    & > .nibnut-app > .body > main > div {
        &, #content, #dashboard {
            height: 100%;
        }
    }

    #dashboard {
        display: flex;
        flex-direction: column;

        h1 {
            flex: 0 0 auto;
        }
        .dashboard {
            flex: 1 0 auto;

            & > .split.split-horizontal, section {
                min-height: 100%;
            }
            & > .split.split-horizontal {
                padding: 0 $control-padding-x-lg;
            }
            .gutter.gutter-horizontal {
                background-color: $bg-color;
            }
            aside {
                height: 100%;
                display: flex;
                flex-direction: column;

                .tab-container > .tab, .search-footer {
                    flex: 0 0 auto;
                }
                .tab-container {
                    flex: 1 1 1px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;

                    & > div {
                        flex: 1 1 1px;
                        overflow: auto;
                        margin-top: $unit-4;
                        margin-bottom: $unit-4;
                    }
                }
                .search-footer {
                    padding: 0 0 $unit-4 0;
                    border-top: $border-width solid $border-color;

                    .search-tools {
                        border-bottom: $border-width solid $border-color;
                    }
                }
            }
            section {
                position: relative;

                .nibnut-data-table thead th,
                .nibnut-data-table tbody td {
                    padding-top: 0;
                    padding-bottom: 0;
                }
                .empty {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;

                    & > div {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}
</style>
