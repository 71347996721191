<template>
    <div id="search-panel">
        <div>
            <div>
                <h6>{{ translate("Search") }}</h6>
                <form-input
                    id="search"
                    name="search"
                    v-model="criteria.query"
                    :required="false"
                    :disabled="disabled"
                    :live="true"
                    :placeholder="translate('Owner first, middle or last name')"
                />
            </div>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-single-value
                :active="!!criteria.status.length"
                :title="translate('Any Status')"
                :active-title="translate('Status')"
                :disabled="disabled"
                @toggle="toggle_criteria('status')"
            >
                <div v-if="!!criteria.status.length" class="choices by-1">
                    <ul>
                        <li
                            v-for="one_status in statii"
                            :key="one_status.id"
                        >
                            <form-toggle-input
                                :id="`choice-${one_status.id}`"
                                name="status_id"
                                type="checkbox"
                                :value="!!choice_active('status', one_status.id)"
                                :required="false"
                                @input="choice_toggle('status', one_status.id)"
                            >
                                {{ one_status.name }}
                            </form-toggle-input>
                        </li>
                    </ul>
                </div>
            </search-section-single-value>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-multi-values
                :value="criteria.amount || []"
                :active="!!criteria.amount"
                :title="translate('Any Amount')"
                :active-title="translate('Amount')"
                :disabled="disabled"
                @toggle="toggle_criteria('amount')"
                @add="add_criteria('amount')"
                @remove="remove_criteria('amount')"
            >
                <template v-slot:default="{ index }">
                    <div
                        class="column"
                    >
                        <form-input
                            :id="`amount-min-${index}`"
                            name="value"
                            type="number"
                            :min="0"
                            v-model="criteria.amount.min"
                            :required="false"
                            :live="true"
                            :placeholder="translate('No Min.')"
                            :disabled="disabled"
                        >
                            {{ translate('Min.') }}
                        </form-input>
                    </div>
                    <div
                        class="column"
                    >
                        <form-input
                            :id="`amount-max-${index}`"
                            name="value"
                            type="number"
                            :min="criteria.amount.min"
                            v-model="criteria.amount.max"
                            :required="false"
                            :live="true"
                            :placeholder="translate('No Max.')"
                            :disabled="disabled"
                        >
                            {{ translate('Max.') }}
                        </form-input>
                    </div>
                </template>
            </search-section-multi-values>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-multi-values
                :value="(criteria.estate === null) ? [] : criteria.estate"
                :active="criteria.estate !== null"
                :title="translate('Estate or Not')"
                :active-title="translate('Estate')"
                :disabled="disabled"
                @toggle="toggle_criteria('estate')"
            >
                <template v-slot:default="{ index }">
                    <div
                        class="column"
                    >
                        <form-toggle-input
                            :id="`estate-true-${index}`"
                            type="radio"
                            name="value"
                            :value="criteria.estate"
                            :disabled="disabled"
                            @input="criteria.estate = true"
                        >
                            {{ translate("Is an Estate") }}
                        </form-toggle-input>
                    </div>
                    <div
                        class="column"
                    >
                        <form-toggle-input
                            :id="`estate-false-${index}`"
                            type="radio"
                            name="value"
                            :true-value="false"
                            :false-value="true"
                            :value="criteria.estate"
                            :disabled="disabled"
                            @input="criteria.estate = false"
                        >
                            {{ translate("Is Not an Estate") }}
                        </form-toggle-input>
                    </div>
                </template>
            </search-section-multi-values>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-single-value
                :active="!!criteria.property_types.length"
                :title="translate('Any Property Type')"
                :active-title="translate('Property Type(s)')"
                :disabled="disabled"
                @toggle="toggle_criteria('property_types')"
            >
                <div v-if="!!criteria.property_types.length" class="choices by-1">
                    <ul>
                        <li
                            v-for="property_type in property_types"
                            :key="property_type.id"
                        >
                            <form-toggle-input
                                :id="`choice-${property_type.id}`"
                                name="property_type_id"
                                type="checkbox"
                                :value="!!choice_active('property_types', property_type.id)"
                                :required="false"
                                @input="choice_toggle('property_types', property_type.id)"
                            >
                                {{ property_type.name }}
                            </form-toggle-input>
                        </li>
                    </ul>
                </div>
            </search-section-single-value>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-single-value
                :active="!!criteria.relationships.length"
                :title="translate('Any Relationship to Owner')"
                :active-title="translate('Relationship(s) to Owner')"
                :disabled="disabled"
                @toggle="toggle_criteria('relationships')"
            >
                <div v-if="!!criteria.relationships.length" class="choices">
                    <ul>
                        <li
                            v-for="relationship in owner_relationships"
                            :key="relationship.id"
                        >
                            <form-toggle-input
                                :id="`choice-${relationship.id}`"
                                name="relationship_id"
                                type="checkbox"
                                :value="!!choice_active('relationships', relationship.id)"
                                :required="false"
                                @input="choice_toggle('relationships', relationship.id)"
                            >
                                {{ relationship.name }}
                            </form-toggle-input>
                        </li>
                    </ul>
                </div>
            </search-section-single-value>
            <!--
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-single-value
                :active="criteria.birth !== null"
                :title="translate('Any Birth Date')"
                :active-title="translate('Birth Date')"
                :disabled="disabled"
                @toggle="toggle_criteria('birth')"
            >
                <form-input
                    v-if="criteria.birth !== null"
                    id="birth"
                    name="value"
                    v-model="criteria.birth"
                    :required="false"
                    :placeholder="translate('YYYY or MMDDYYYY')"
                    :disabled="disabled"
                >
                    {{ translate('Birth Date') }}
                </form-input>
            </search-section-single-value>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-multi-values
                :value="criteria.age_range || []"
                :active="!!criteria.age_range"
                :title="translate('Any Age')"
                :active-title="translate('Age')"
                :disabled="disabled"
                @toggle="toggle_criteria('age_range')"
                @add="add_criteria('age_range')"
                @remove="remove_criteria('age_range')"
            >
                <template v-slot:default="{ index }">
                    <div
                        class="column"
                    >
                        <form-input
                            :id="`age_range-min-${index}`"
                            name="value"
                            type="number"
                            :min="1"
                            v-model="criteria.age_range.min"
                            :required="false"
                            :live="true"
                            :placeholder="translate('No Min.')"
                            :disabled="disabled"
                        >
                            {{ translate('Min.') }}
                        </form-input>
                    </div>
                    <div
                        class="column"
                    >
                        <form-input
                            :id="`age_range-max-${index}`"
                            name="value"
                            type="number"
                            :min="criteria.age_range.min"
                            v-model="criteria.age_range.max"
                            :required="false"
                            :live="true"
                            :placeholder="translate('No Max.')"
                            :disabled="disabled"
                        >
                            {{ translate('Max.') }}
                        </form-input>
                    </div>
                </template>
            </search-section-multi-values>
            //-->
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-multi-values
                :value="criteria.reported_year_range || []"
                :active="!!criteria.reported_year_range"
                :title="translate('Any Reported Year')"
                :active-title="translate('Reported Year')"
                :disabled="disabled"
                @toggle="toggle_criteria('reported_year_range')"
                @add="add_criteria('reported_year_range')"
                @remove="remove_criteria('reported_year_range')"
            >
                <template v-slot:default="{ index }">
                    <div
                        class="column"
                    >
                        <form-input
                            :id="`reported_year_range-min-${index}`"
                            name="value"
                            type="number"
                            :min="1800"
                            v-model="criteria.reported_year_range.min"
                            :required="false"
                            :live="true"
                            :placeholder="translate('No Min.')"
                            :disabled="disabled"
                        >
                            {{ translate('Min.') }}
                        </form-input>
                    </div>
                    <div
                        class="column"
                    >
                        <form-input
                            :id="`reported_year_range-max-${index}`"
                            name="value"
                            type="number"
                            :min="criteria.reported_year_range.min"
                            v-model="criteria.reported_year_range.max"
                            :required="false"
                            :live="true"
                            :placeholder="translate('No Max.')"
                            :disabled="disabled"
                        >
                            {{ translate('Max.') }}
                        </form-input>
                    </div>
                </template>
            </search-section-multi-values>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-single-value
                :active="!!criteria.holders"
                :title="translate('Any Holder')"
                :active-title="translate('Holder(s)')"
                :disabled="disabled"
                @toggle="toggle_criteria('holders')"
            >
                <form-tag-input
                    v-if="criteria.holders !== null"
                    id="holder_id"
                    name="holder_id"
                    :value="criteria.holders || []"
                    data-source="holder"
                    :show-all="false"
                    :required="false"
                    @input="criteria.holders = $event"
                />
            </search-section-single-value>
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-multi-values
                :value="(criteria.multiple_accounts === null) ? [] : criteria.multiple_accounts"
                :active="criteria.multiple_accounts !== null"
                :title="translate('Multiple or Unique Accounts')"
                :active-title="translate('Accounts')"
                :disabled="disabled"
                @toggle="toggle_criteria('multiple_accounts')"
            >
                <template v-slot:default="{ index }">
                    <div
                        class="column"
                    >
                        <form-toggle-input
                            :id="`multiple_accounts-true-${index}`"
                            type="radio"
                            name="value"
                            :value="criteria.multiple_accounts"
                            :disabled="disabled"
                            @input="criteria.multiple_accounts = true"
                        >
                            {{ translate("Multiple Accounts") }}
                        </form-toggle-input>
                    </div>
                    <div
                        class="column"
                    >
                        <form-toggle-input
                            :id="`multiple_accounts-false-${index}`"
                            type="radio"
                            name="value"
                            :true-value="false"
                            :false-value="true"
                            :value="criteria.multiple_accounts"
                            :disabled="disabled"
                            @input="criteria.multiple_accounts = false"
                        >
                            {{ translate("Unique Accounts") }}
                        </form-toggle-input>
                    </div>
                </template>
            </search-section-multi-values>
            <div class="divider" :data-content="translate('AND')"></div>
            <!--
            Genealogy
            <div class="divider" :data-content="translate('AND')"></div>
            Contact Verified
            <div class="divider" :data-content="translate('AND')"></div>
            Input Contact
            <div class="divider" :data-content="translate('AND')"></div>
            Input CRM
            <div class="divider" :data-content="translate('AND')"></div>
            //-->
            <search-section-multi-values
                :value="criteria.addresses"
                :active="!!criteria.addresses.length"
                :title="translate('Any Address')"
                :active-title="translate('Address')"
                :placeholder="translate('123 2nd Street, City, State, Zip')"
                :operators="operators_for('addresses')"
                :disabled="disabled"
                @toggle="toggle_criteria('addresses')"
                @add="add_criteria('addresses')"
                @remove="remove_criteria('addresses')"
            />
            <!--
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-multi-values
                :value="criteria.phone_numbers"
                :active="!!criteria.phone_numbers.length"
                :title="translate('Any Phone Number')"
                :active-title="translate('Phone Number')"
                :placeholder="translate('(123) 456-7890')"
                :operators="operators_for('phone_numbers')"
                :disabled="disabled"
                @toggle="toggle_criteria('phone_numbers')"
                @add="add_criteria('phone_numbers')"
                @remove="remove_criteria('phone_numbers')"
            />
            //-->
            <div class="divider" :data-content="translate('AND')"></div>
            <search-section-single-value
                :active="!!criteria.type"
                :title="translate('Any Type')"
                :active-title="translate('Type')"
                :disabled="disabled"
                @toggle="toggle_criteria('type')"
            >
                <div
                    v-if="!!criteria.type"
                    class="columns"
                >
                    <form-toggle-input
                        id="type-P"
                        type="radio"
                        name="type"
                        true-value="P"
                        :false-value="null"
                        :value="criteria.type"
                        :disabled="disabled"
                        class="column"
                        @input="criteria.type = 'P'"
                    >
                        {{ translate("Individual(s)") }}
                    </form-toggle-input>
                    <form-toggle-input
                        id="type-B"
                        type="radio"
                        name="type"
                        true-value="B"
                        :false-value="null"
                        :value="criteria.type"
                        :disabled="disabled"
                        class="column"
                        @input="criteria.type = 'B'"
                    >
                        {{ translate("Business") }}
                    </form-toggle-input>
                </div>
            </search-section-single-value>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { misc_utilities } from "@/nibnut/mixins"

import {
    FormInput,
    FormToggleInput,
    FormTagInput
} from "@/nibnut/components"

import SearchSectionSingleValue from "./SearchSectionSingleValue"
import SearchSectionMultiValues from "./SearchSectionMultiValues"

export default {
    name: "SearchPanel",
    mixins: [misc_utilities],
    components: {
        FormInput,
        FormToggleInput,
        FormTagInput,
        SearchSectionSingleValue,
        SearchSectionMultiValues
    },
    methods: {
        operators_for (criteria) {
            const operators = []
            if(criteria !== "addresses") {
                operators.push({ id: "=", name: this.translate("Equal") })
                operators.push({ id: "!=", name: this.translate("Not Equal") })
            } else operators.push({ id: ".", name: this.translate("Contain") })

            if(criteria === "phone_numbers") {
                operators.push({ id: "^", name: this.translate("Start with") })
                operators.push({ id: ".", name: this.translate("Contain") })
                operators.push({ id: "!.", name: this.translate("Not Contain") })
                operators.push({ id: "$", name: this.translate("End with") })
            }
            /*
            if(criteria === "addresses") {
                operators.push({ id: "?", name: this.translate("Partial") })
            }
            */
            /*
            if(criteria === "...") {
                operators.push({ id: "??", name: this.translate("Unidentified") })
            }
            */
            return operators
        },
        criteria_filled (collection) {
            let filled = (this.criteria[collection] !== null)
            switch (collection) {
            case "property_types":
            case "relationships":
            case "addresses":
            case "status":
            case "phone_numbers":
                filled = !!this.criteria[collection].length
                break
            }
            return filled
        },
        toggle_criteria (collection) {
            if(this.criteria_filled(collection)) {
                let empty_value = null
                switch (collection) {
                case "property_types":
                case "relationships":
                case "addresses":
                case "status":
                case "phone_numbers":
                    empty_value = []
                    break
                default:
                    empty_value = null
                    break
                }
                this.$set(this.criteria, collection, empty_value)
            } else this.add_criteria(collection)
        },
        remove_criteria (collection, index) {
            this.criteria[collection].splice(index, 1)
        },
        add_criteria (collection) {
            switch (collection) {
            case "property_types":
            case "relationships":
            case "status":
                this.criteria[collection].push(this.collection_options(collection)[0].id)
                break
            case "phone_numbers":
            case "addresses":
                this.criteria[collection].push({ operator: ".", value: "" })
                break
            case "amount":
            case "age_range":
            case "reported_year_range":
                this.$set(this.criteria, collection, { min: null, max: null })
                break
            case "estate":
            case "multiple_accounts":
            case "unidentified":
                this.$set(this.criteria, collection, true)
                break
            case "type":
                this.$set(this.criteria, collection, "P")
                break
            case "holders":
                this.$set(this.criteria, collection, [])
                break
            default:
                this.$set(this.criteria, collection, "")
            }
        },
        collection_options (collection) {
            if(collection === "status") {
                return orderBy(
                    this.statii,
                    "name",
                    "asc"
                )
            }
            if(collection === "property_types") {
                return orderBy(
                    this.property_types.map(property_type => {
                        return { id: property_type.id, name: `${property_type.code} ${property_type.name}` }
                    }),
                    "name",
                    "asc"
                )
            }
            if(collection === "relationships") {
                return orderBy(
                    this.owner_relationships.map(relationship => {
                        return { id: relationship.id, name: `${relationship.code} ${relationship.name}` }
                    }),
                    "name",
                    "asc"
                )
            }
            return [{ id: 0, name: "" }]
        },
        choice_active (collection, id) {
            return this.criteria[collection].indexOf(id) >= 0
        },
        choice_toggle (collection, id) {
            const index = this.criteria[collection].indexOf(id)
            if(index < 0) this.criteria[collection].push(id)
            else this.criteria[collection].splice(index, 1)
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        property_types () {
            return this.entity_records("property_type")
        },
        owner_relationships () {
            return this.entity_records("owner_relationship")
        },
        statii () {
            return Object.values(this.constants("owner_statii"))
        }
    },
    props: {
        criteria: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

#search-panel {
    overflow-y: auto;

    h6 > button.btn {
        text-align: left;
        font-weight: bold;

        .las.la-angle-right {
            transition: transform 0.1s ease-in-out;
        }

        &.active .las.la-angle-right {
            transform: rotate(90deg);
        }
    }
    .divider.borderless {
        text-align: center;
        border-top: 0;
    }
    select.form-select {
        font-size: 0.7rem;
        max-width: 100%;
    }
    .btn-remove {
        padding-left: 0;
    }

    .left .base-date-input.popover.popover-bottom.active {
        & > .popover-container { transform: translate(-30%, 0) scale(1); }
    }
    .right .base-date-input.popover.popover-bottom.active {
        & > .popover-container { transform: translate(-70%, 0) scale(1); }
    }
    .choices {
        ul {
            display: grid;
            grid-template-columns: repeat(auto-fit, 50%);
            justify-items: stretch;
            justify-content: end;
            margin: 0;
            max-width: 100%;

            & > li {
                font-size: 0.8em;
                padding-left: $control-padding-x;
                margin-top: 0;

                .form-checkbox, .form-radio, .form-switch {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
        &.by-1 {
            ul {
                display: block;
                grid-template-columns: none;
            }
        }
    }
}
</style>
