<template>
    <div>
        <h6>
            <default-button
                flavor="link"
                size="sm"
                :block="true"
                :class="{ active }"
                :disabled="disabled"
                @click.prevent="$emit('toggle')"
            >
                <open-icon glyph="angle-right" class="mr-1" />
                <span v-if="active">{{ activeTitle }}</span>
                <span v-else>{{ title }}</span>
            </default-button>
        </h6>
        <div
            v-for="(value, index) in rows"
            :key="index"
        >
            <div class="columns">
                <slot :value="value" :index="index">
                    <div
                        v-if="!!operators.length"
                        :class="{ 'flex-static': !value.operator.match(/^\?+$/), 'flex-variable': value.operator.match(/^\?+$/) }"
                        class="column"
                    >
                        <form-dropdown
                            :id="`operator-${index}`"
                            name="operator"
                            v-model="value.operator"
                            :options="operators"
                            :required="true"
                            :disabled="disabled"
                        />
                    </div>
                    <div
                        v-if="!value.operator.match(/^\?+$/)"
                        class="column flex-variable"
                    >
                        <form-input
                            :id="`activeTitle-${index}`"
                            name="value"
                            v-model="value.value"
                            :required="false"
                            :live="true"
                            :placeholder="placeholder"
                            :disabled="disabled"
                        />
                    </div>
                </slot>
                <div
                    v-if="is_multiple && (value.length > 1)"
                    class="column flex-static"
                >
                    <default-button
                        flavor="link"
                        class="btn-remove"
                        :disabled="disabled"
                        @click.prevent="$emit('remove', index)"
                    >
                        <open-icon glyph="minus-circle" />
                    </default-button>
                </div>
            </div>
            <div
                v-if="is_multiple && (index < (value.length - 1))"
                class="divider borderless"
                :data-content="$root.translate('OR')"
            ></div>
            <default-button
                v-else-if="is_multiple"
                flavor="link"
                color="primary"
                size="sm"
                :block="true"
                :disabled="disabled"
                @click.prevent="$emit('add')"
            >
                {{ $root.translate("OR") }}
            </default-button>
        </div>
    </div>
</template>

<script>
import {
    FormInput,
    FormDropdown,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "SearchSectionMultiValues",
    components: {
        FormInput,
        FormDropdown,
        DefaultButton,
        OpenIcon
    },
    computed: {
        is_multiple () {
            return Array.isArray(this.value)
        },
        rows () {
            if(Array.isArray(this.value)) return this.value
            else return [this.value]
        }
    },
    props: {
        value: {
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        activeTitle: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        operators: {
            type: Array,
            default () {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
